:root{
    --primary-bg: #000000;
    --secondary-bg: #FAFAFA;
    --tertiary-bg: #5384B2;
    --primary-color: #ffffff;
    --secondary-color: #F47C5A;
    --tertiary-color: #3E8CBE;
    --transparent:rgba(0,0,0,0);
}

.from-bg-primary   {--from-color : var(--primary-bg);}
.from-bg-secondary {--from-color : var(--secondary-bg);}
.from-bg-tertiary  {--from-color : var(--tertiary-bg);}
.from-transparent  {--from-color : var(--transparent);}

.to-bg-primary   {--to-color : var(--primary-bg);}
.to-bg-secondary {--to-color : var(--secondary-bg);}
.to-bg-tertiary  {--to-color : var(--tertiary-bg);}
.to-transparent  {--to-color : var(--transparent);}


.bg-primary{background-color: var(--primary-bg);}
.bg-secondary{background-color: var(--secondary-bg);}
.bg-tertiary{background-color: var(--tertiary-bg);}

.color-primary{color: var(--primary-color);}
.color-secondary{color: var(--secondary-color);}
.color-tertiary{color: var(--tertiary-color);}
.color-bg-primary{color: var(--primary-bg);}