//Static Styles
.flex{display: flex;}
.flex-row{display: flex; flex-direction: row;}
.flex-row-reverse{display: flex; flex-direction: row-reverse;}
.flex-col{display: flex; flex-direction: column;}
.flex-col-reverse{display: flex; flex-direction: column-reverse;}

.items-center{align-items: center;}
.justify-center{justify-content: center;}
.justify-end{justify-content: flex-end;}
.center-both{align-items: center; justify-content: center;}


/* Hide scrollbar for Chrome, Safari and Opera */  
/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}


.bg-gradient-right{
    --from-color:red;
    --to-color: blue;
    background: var(--from-color);
    background: -moz-linear-gradient(90deg, var(--from-color) 0%, var(--to-color) 100%);
    background: -webkit-linear-gradient(90deg, var(--from-color) 0%, var(--to-color) 100%);
    background: linear-gradient(90deg, var(--from-color) 0%, var(--to-color) 100%);
}

.w-screen{width:100vw;}
.h-screen{height:100vh;}
.size-screen{width: 100vw; height: 100vh;}
.size-100{width: 100%; height: 100%;}
.size-full{width: 100%; height: 100%;}

.border-box{box-sizing: border-box;}

.rel{position:relative}
.abs{position:absolute;left:0;top:0}

.overflow-hiden{overflow: hidden;}

.no-decoration{text-decoration: none}

.b-0{border: none;}
.b-none{border: none;}
.m-auto{margin: auto;}

.transition-all{transition: all ease-in-out 300ms;}

.cursor-pointer{cursor:pointer}

.events-none{pointer-events: none;}
.events-all{pointer-events: all;}


//Dinamic Styles
$decimals:0 10 20 30 40 50 60 70 80 90 100;
@each $var in $decimals {
    .z-#{$var}{z-index: $var;}
    .-z-#{$var}{z-index: -$var;}

    .opacity-#{$var}{opacity: $var * 1%;}

    .h-#{$var}{height: $var * 1%;}
    .w-#{$var}{width: $var * 1%;}

    .mb-#{$var}{margin-bottom: $var * 1px;}
    .mt-#{$var}{margin-top:    $var * 1px;}
    .ml-#{$var}{margin-left:   $var * 1px;}
    .mr-#{$var}{margin-right:  $var * 1px;}
    .m-#{$var}{margin:         $var * 1px;}

    .pb-#{$var}{margin-bottom: $var * 1px;}
    .pt-#{$var}{margin-top:    $var * 1px;}
    .pl-#{$var}{margin-left:   $var * 1px;}
    .pr-#{$var}{margin-right:  $var * 1px;}
    .p-#{$var}{margin:         $var * 1px;}
}