@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src:
         url($font-file+'.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

// @include font("Montserrat",'./Assets/fonts/Montserrat-Regular');
// @include font("Montserrat-Light",'./Assets/fonts/Montserrat-Light');

input{
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type="search"]{
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

::placeholder{
  color: #141414;
  opacity: 70%;
}

:root , html{
  background-color: black;
}

$font-stack: Montserrat,'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

html,body{
  overflow: hidden;
  max-height: 100vh;
  overflow-y: hidden;
}
body {
  position: relative;
  margin: 0;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

