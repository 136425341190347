.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@keyframes appearAnimation {
  0%{
    opacity: 0;
    transform: translateX(-100px);
  }

  100%{
    opacity: 100%;
    transform: translateX(0px);

  }
  
}

.appear{
  opacity: 0;
  animation-name: appearAnimation;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 300ms;
  animation-delay: var(--appear-delay);

}

.active{
  transition: ease-in-out 300ms;
}

.active:active{
  opacity: 80%;
  transform: scale(0.97)translate(0px);
}